import React, { useState, useEffect } from "react";
import './navbar.css';
import Logo from '../Navbar/sheep.svg';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import supabase from '../../supabaseClient/supabaseClient'
import { useAuth } from "../Login/AuthContext";
import Facebook from '../../assets/hamburgermenu_icons/facebook.svg';
import Instagram from '../../assets/hamburgermenu_icons/instagram.svg';
import tiktok from '../../assets/hamburgermenu_icons/tiktok.svg';
import home from '../../assets/hamburgermenu_icons/home.svg';
import blogs from '../../assets/hamburgermenu_icons/blogs.svg';
import tuts from '../../assets/hamburgermenu_icons/tutorials.svg';
import feedback from '../../assets/hamburgermenu_icons/feedback.svg';
import logout from '../../assets/hamburgermenu_icons/logout.svg';
import account from '../../assets/hamburgermenu_icons/account.svg'
import credits from '../../assets/hamburgermenu_icons/credits.svg'

const Navbar = ({ onMenuToggle }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { worksheetsCount, setWorksheetsCount } = useAuth();
  const { isAuthenticated, user } = useAuth();


  const toggleMenu = () => {
    const newState = !isMenuOpen;
    setMenuOpen(newState);
    onMenuToggle(newState); // Notify Layout about the menu state
};


const handleLogout = async () => {

  const { error } = await supabase.auth.signOut();

  if (error) {
    console.error('Error signing out:', error.message);
  } else {
  
    navigate('/login');
  }
};

 

  return (
  <div className='navbar'>
      <div className='navbar_logo-title'>
      <button className="hamburger" onClick={toggleMenu}>
                    ☰
                </button>
        <img src={Logo} alt='Logo' className='navbar_logo' />
        <h1 className='navbar_title'>
        <Link to="/">
          Worksheep
          </Link>
          </h1>

      </div>


      {isAuthenticated && (
  <div className="navbar-worksheets-left">
    <span className="worksheets-left-text">
      📄 :
      <span className="worksheets-count"> {worksheetsCount} </span>
    </span>
  </div>
)}


      <div className="navbar-sign-in-btn">
      {isAuthenticated && user ? (
        <span className="logged-useremail">Welcome, {user.email}</span>
      ) : (
        <button className="sign-in-btn">
          <Link to="/login">Sign In / Create Account</Link>
        </button>
      )}
    </div>


    


    <div className={`hamburger-menu ${isMenuOpen ? "open" : ""}`}>
  <ul className="menu-list">
    {/* Group 1 */}
    <div className="menu-group">
      <li className='menu-item'>
      <img src={home} alt="home" className="menu-icon" />
        <a href='https://www.theworksheep.com/' target='_blank' rel='noopener noreferrer'>
          <i className="icon-home"></i> Home
        </a>

      </li>
      <li className='menu-item'>
      <img src={blogs} alt="blogs" className="menu-icon" />
        <a href='https://www.theworksheep.com/blogs'target='_blank' rel='noopener noreferrer'>
          <i className="icon-item1"></i> Blogs
        </a>
      </li>
    </div>
    
    <hr className="menu-divider" />
    
    {/* Group 2 */}
    <div className="menu-group">
      <li className='menu-item'>
      <img src={tuts} alt="tutorials" className="menu-icon" />
        <a href='https://www.youtube.com/watch?v=co4koppeTUs&t=7s' target='_blank' rel='noopener noreferrer'>
          <i className="icon-tutorials"></i> Tutorials
        </a>
      </li>
      <li className='menu-item'>
      <img src={feedback} alt="tutorials" className="menu-icon" />
        <a href='https://forms.gle/iTGhS8mc2CCreumB7' target='_blank' rel='noopener noreferrer'>
          <i className="icon-feedback"></i> Feedback
        </a>
      </li>
    </div>
    
    <hr className="menu-divider" />
    
    {/* Group 3 */}
    <div className="menu-group menu-group-bottom">

    <li className="menu-item">
  <img src={account} alt="Account" className="menu-icon" />
  <Link to="/account" className="menu-link">
    <i className="icon-logout"></i> Account
  </Link>
</li>

<li className="menu-item">
  <img src={credits} alt="buy_credits" className="menu-icon" />
  <Link to="/buycredits" className="menu-link">
    <i className="icon-logout"></i> Buy Credits
  </Link>
</li>

      <li className='menu-item'>
      <img src={logout} alt="tutorials" className="menu-icon" />
        <a onClick={handleLogout}>
          <i className="icon-logout"></i> Logout
        </a>
      </li>
      <li className='social-icons'>
        <a href='https://www.facebook.com/profile.php?id=61563133166252' target='_blank' rel='noopener noreferrer'>
          <img src={Facebook} alt="Facebook" className="menu-icon" />
        </a>
        <a href='https://www.instagram.com/theworksheeeeeep?fbclid=IwY2xjawFzaj1leHRuA2FlbQIxMAABHdFJIXhStu1eO_QuR0RGUc-IpJOYWz_MnBgII81cCJFlMFQdVbtZCz96Gw_aem_aZqAMr2ShhC1y3YlatZIqg' target='_blank' rel='noopener noreferrer'>
          <img src={Instagram} alt="Instagram" className="menu-icon" />
        </a>
        <a href='https://www.tiktok.com/@theworksheeeeeep?lang=en' target='_blank' rel='noopener noreferrer'>
          <img src={tiktok} alt="Instagram" className="menu-icon" />
        </a>
      </li>
    </div>
  </ul>
</div>




    
  </div>
  );
}

export default Navbar;