import supabase from "../../supabaseClient/supabaseClient";

export const deductCredits = async (userId, amount = 1, setWorksheetsCount) => {
    if (!userId) return;
  
    try {
      // Fetch current credits
      const { data, error } = await supabase
        .from("profiles") // Ensure table name is correct
        .select("credits")
        .eq("id", userId)
        .single();
  
      if (error) {
        console.error("Error fetching credits:", error);
        return;
      }
  
      const currentCredits = data?.credits || 0;
      const newCredits = Math.max(0, currentCredits - amount);
  
      // Update credits in the database
      const { error: updateError } = await supabase
        .from("profiles")
        .update({ credits: newCredits })
        .eq("id", userId);
  
      if (updateError) {
        console.error("Error updating credits:", updateError);
      } else {
      
        
        // Update global state with new credits count
        setWorksheetsCount(newCredits);
      }
    } catch (err) {
      console.error("Unexpected error deducting credits:", err);
    }
  };
