export const faqs = [
  {
    question: "What are credits and what can I do with credits?",
    answer: "Credits are a form of virtual tokens that you can purchase with real money and use to generate worksheets."
  },
  {
    question: "How many credits for a single worksheet?",
    answer: "One credit per worksheet. For everytime you click on generate to create a worksheet of questions - It will cost you one credit"
  },
  {
    question: "Can I get a refund for unused credits?",
    answer: "We do not offer refunds on purchased credits, but you can use them anytime."
  },
  {
    question: "What payment methods can I use?",
    answer: "You can pay through Paypal or credit card. We accept credit or debit card from following: Visa, Mastercard, American Express, JCB, and More."
  },
];