import './Layout.css';



import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Paper, CircularProgress  } from '@mui/material';
import { pdf } from '@react-pdf/renderer';
import { toPng } from 'html-to-image';


import Texteditor from '../Texteditor/Texteditor.js';
import TemplateCarousel from '../TemplateCarousel/TemplateCarousel.js';
import Popup from '../MobilePopUp/Popup.jsx';
import Columnquestions from '../ColumnQuestions/Columnquestions.js';  
import ColumnAdditionTemplate from '../pdfdoc/template2.js'
import AiGenerate from '../aiPrompt/aiprompt';
import Navbar from '../Navbar/navbar';
import QuestionsPreview from '../QuestionsPreview/QuestionsPreview.jsx';
import MyDocument from '../pdfdoc/pdfformathjax.js'
import Geometryqs from '../Geometryqs/Geometryqs.js';
import axios from 'axios';



export const Context = React.createContext();

function Layout() {
  const [selectedSubjects, setSelectedSubjects] = useState(null);
  const [worksheetName, setWorksheetName] = useState('');
  const [textbox1, setTextbox1] = useState('');
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [pdfUrl, setPdfUrl] = useState(''); 
  const [questionsArray, setQuestionsArray] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(null);  
  const [isMenuOpen, setMenuOpen] = useState(false)
  const refs = useRef([]);

  const contextValue = {
    selectedSubjects,
    setSelectedSubjects,
    worksheetName,
    setWorksheetName,
    textbox1,
    setTextbox1
  };

  const handleMenuToggle = (state) => {
    setMenuOpen(state); // Update state based on Navbar interaction
};

  const generateContentAndPdf = async (prompt) => {
    setLoading(true);
    setLoadingMessage('Questions generating...');
    setError('');
    setPdfUrl(''); // Clear previous PDF URL
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_BE_URL}/generate`, {
        prompt,
        model: 'llama3-8b-8192', // Specify the model here as needed
      });
  
      setFilteredQuestions(response.data);

      setQuestionsArray([]); // Clear questions array to prepare for new questions
  
    } catch (error) {
      console.error('Error generating content and processing data:', error);
      setError('Error generating content and processing data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const generatePdf = useCallback(async () => {
    if (questionsArray.length === 0 || !selectedTemplate) return;

    let TemplateStyle;
    
    // Ensure selectedTemplate is valid
    if (selectedTemplate === 'exam-style') {
      TemplateStyle = MyDocument;
    } else if (selectedTemplate === 'column-template') {
      TemplateStyle = ColumnAdditionTemplate;
    } else {
      console.error('Invalid template selected');
      return;
    }

    if (!TemplateStyle) {
      console.error('TemplateStyle is undefined');
      return; // Prevent generating the PDF with an undefined component
    }
    const pdfBlob = await pdf(
      <TemplateStyle questions={questionsArray} textbox1={textbox1} />
    ).toBlob();
    
    const url = URL.createObjectURL(pdfBlob);
    setPdfUrl(url);
    // window.open(url, '_blank');
  }, [questionsArray]);  // Watch questionsArray instead of loading


  useEffect(() => {
    generatePdf(); 
  }, [generatePdf]);



  useEffect(() => {
    const captureImages = async () => {
      const questionsArray = [];
      setLoading(true);
      setLoadingMessage('Preparing PDF...');
  
      for (let i = 0; i < filteredQuestions.length; i++) {
        const item = filteredQuestions[i];
        const refElement = refs.current[i];
        
        if (item.question) {
          // Check the selected template
          if (selectedTemplate === 'exam-style') {
            // Convert all questions to PNG if template is "exam-style"
            const dataUrl = await toPng(refElement, { height: 100 });
            questionsArray.push({ type: 'png-question', content: dataUrl });
          } else {
            // Otherwise, process based on content type (text or LaTeX)
            if (item.question.latex) {
              const dataUrl = await toPng(refElement, { height: 100 });
              questionsArray.push({ type: 'latex-question', content: dataUrl });
            } else if (item.question.text) {
              questionsArray.push({ type: 'text-question', content: item.question.text });
            }
          }
        } else if (item.answer) {
          // Process answers
          if (selectedTemplate === 'exam-style' || item.answer.latex) {
            const dataUrl = await toPng(refElement, { height: 100 });
            questionsArray.push({ type: 'latex-answer', content: dataUrl });
          } else if (item.answer.text) {
            questionsArray.push({ type: 'text-answer', content: item.answer.text });
          }
        }
      }
  
      setQuestionsArray(questionsArray);
      setLoading(false);
    };
  
    if (filteredQuestions.length > 0) {
      captureImages(); // Capture images whenever filteredQuestions changes
    }
  }, [filteredQuestions]);
  


  const handleOpenPdf = () => {
    if (pdfUrl) {
      window.open(pdfUrl, '_blank'); // Open PDF URL in a new tab
    }


  };


  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template); // Set selected template when user picks one
  };
  
  
  const handleBack = () => {
    setPdfUrl(null); // Reset the PDF URL
    setSelectedTemplate(null);
    setSelectedSubjects(null);
    

  };




  return (
    <div>
    <Navbar onMenuToggle={handleMenuToggle} />
    <div 
      className={`layout-body ${isMenuOpen ? "menu-open" : ""}`}
      style={{
      marginLeft: isMenuOpen ? "250px" : "0", // Adjust based on menu state
      transition: "margin-left 0.3s ease-in-out", // Smooth transition
      }}
      >

    <div className='layout-body'>
      <Popup/>
     
      <div className="layout">
        <Context.Provider value={contextValue}>
        <div className="left-half">
            <Paper className="editor">
                <h3 className='card-title'>Editor</h3>

                {/* Conditional rendering based on whether a template is selected */}
                {!selectedTemplate ? (
                  <TemplateCarousel onSelectTemplate={handleTemplateSelect} />
                ) : (
                  <>
                    {/* Render different components based on the selected template */}

                    {selectedTemplate === 'exam-style' && (
                      <AiGenerate generateContentAndPdf={generateContentAndPdf} loading={loading} />
                    )}

                    {selectedTemplate === 'column-template' && (
                      <Columnquestions generateContentAndPdf={generateContentAndPdf} />
                    )}

                    {/* You can add more components here based on the template */}
                
                    {selectedTemplate === 'geometry-template' && (
                      <Geometryqs />
                    )}
                   

                    {/* Loader and error message */}
                    <div className="button-container">
  <button 
  className="circular-back-button" 
  onClick={handleBack}>
    &lt;
  </button>
</div>
                    {loading && (
                      <div className="loader-container">
                        <CircularProgress className="loader-spinner" />
                        <p>{loadingMessage}</p> {/* Display the loading message */}
                      </div>
                    )}
                    {error && <p className="error-text">{error}</p>}
                  </>
                )}
              </Paper>

          <Paper className="viewer">
            <h2 className='card-title'>Lessons Selected:</h2>
            {/* <button
              className="pdf-button"
              onClick={handleOpenPdf}
              disabled={!pdfUrl} // Button is disabled if pdfUrl is empty
            >
              Open PDF
            </button> */}
            <div className='selectedlessons'>
              <p><br /><br />
                <span dangerouslySetInnerHTML={{ __html: selectedSubjects }}></span>
              </p>
            </div>
          </Paper>
        </div>



<div className='right-half'>
{selectedTemplate === 'exam-style' ? (
                  // Render Texteditor for AiGenerate template
                  <Texteditor
                    questions={filteredQuestions}
                    refs={refs}
                  />
                ) : (
                  // Render QuestionsPreview for Columnquestions and Geometryqs templates
                  <>
                    {selectedTemplate === 'column-template' && (
                      <QuestionsPreview
                        questions={filteredQuestions}
                        refs={refs}
                      />
                    )}
                    {selectedTemplate === 'geometry-template' && (
                      <QuestionsPreview
                        questions={filteredQuestions}
                        refs={refs}
                      />
                    )}
                  </>
                )}


</div>




        </Context.Provider>
      </div>
    </div>
    </div>
    </div>
  );
}

export default Layout;