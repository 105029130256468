import axios from "axios";
import "./Buycredits.css";
import Navbar from "../Navbar/navbar";
import down from '../../assets/hamburgermenu_icons/down.svg'
import credits from '../../assets/hamburgermenu_icons/credits.svg'
import React, { useEffect, useState } from "react";
import { useAuth } from "../Login/AuthContext";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

import { faqs } from "./faq";

const Buycredits = () => {

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [btnLoading, setBtnLoading] = useState({ Starter: false, Essential: false, Pro: false });
  const { user } = useAuth();


  const Starter = async () => {
    try {
    
    setBtnLoading((prev) => ({ ...prev, Starter: true })); 
      const response = await axios.post(`${process.env.REACT_APP_BE_URL}/checkout`, {
        productId: process.env.REACT_APP_STARTER_VARIANT_ID,
        customData: {
          user_id: user.id,
          credits: "10",
        },
      });

      if (response.data?.checkoutUrl) {
        window.location.href = response.data.checkoutUrl;
      }
    } catch (error) {
      console.error("Checkout creation failed:", error);
    } finally {
        setBtnLoading((prev) => ({ ...prev, Starter: false })); // Set loading to false
      }
    };

  const Essential = async () => {
    try {
    setBtnLoading((prev) => ({ ...prev, Essential: true })); 
      const response = await axios.post(`${process.env.REACT_APP_BE_URL}/checkout`, {
        productId: process.env.REACT_APP_STANDARD_VARIANT_ID,
        customData: {
            user_id: user.id,
            credits: "20",
        },
      });


      if (response.data?.checkoutUrl) {
        window.location.href = response.data.checkoutUrl;
      }
    } catch (error) {
      console.error("Checkout creation failed:", error);
    } finally {
        setBtnLoading((prev) => ({ ...prev, Essential: false })); // Set loading to false
      }
    };

    const Pro = async () => {
        try {
        setBtnLoading((prev) => ({ ...prev, Pro: true })); 
          const response = await axios.post(`${process.env.REACT_APP_BE_URL}/checkout`, {
            productId: process.env.REACT_APP_BULK_VARIANT_ID,
            customData: {
                user_id: user.id,
                credits: "50",
            },
          });

    
          if (response.data?.checkoutUrl) {
            window.location.href = response.data.checkoutUrl;
          }
        } catch (error) {
          console.error("Checkout creation failed:", error);
        } finally {
            setBtnLoading((prev) => ({ ...prev, Pro: false })); // Set loading to false
          }
        };

  const handleMenuToggle = (state) => {
    setMenuOpen(state);
  };




  return (
    <div className="buy-page-body">
      <Navbar onMenuToggle={handleMenuToggle} />
      <div
        className={`books-container ${isMenuOpen ? "menu-open" : ""}`}
        style={{
          marginLeft: isMenuOpen ? "250px" : "0", // Adjust based on menu state
          transition: "margin-left 0.3s ease-in-out", // Smooth transition
        }}
      >
      <div className="credits-header">
         <img src={credits} alt="buy_credits" className="credits-icon" />
        <h1 className="credits-title">Credit Packs</h1>
        </div>

        <div className="div-for-all-pricing">
          <div className="pricing-card">
            <div className="book-details">
              <p className="pricing-title">Starter Pack</p>
              <p className="pricing-price">$2.99</p>
              <p className="pricing-description">10 Credits</p>
              <strong className="pricing-price"></strong>
              <button 
                className="pricing-button"
                onClick={() => Starter()} 
                disabled={btnLoading.Starter}
              >
                {btnLoading.Starter ? <span className="spinner"></span> : "Buy Now"}
              </button>
            </div>
          </div>

          <div className="pricing-card">
            <div className="book-details">
              <p className="pricing-title">Standard Pack</p>
              <p className="pricing-price">$4.99</p>
              <p className="pricing-description">20 Credits</p>
              <strong className="pricing-price"></strong>
              <button className="pricing-button" onClick={() => Essential()} disabled={btnLoading.Essential}
              >
                {btnLoading.Essential ? <span className="spinner"></span> : "Buy Now"}
              </button>
            </div>
          </div>


          <div className="pricing-card">
            <div className="book-details">
              <p className="pricing-title">Bulk Pack</p>
              <p className="pricing-price">$9.99</p>
              <p className="pricing-description">50 Credits</p>
              <strong className="pricing-price"></strong>
              <button className="pricing-button" onClick={() => Pro()} disabled={btnLoading.Pro}
              >
                {btnLoading.Pro ? <span className="spinner"></span> : "Buy Now"}
              </button>
            </div>
          </div>

        </div>

    <div className="faq-container">
      {faqs.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary
          expandIcon={<img src={down} alt="down_icon" width={24} height={24} />}
          >
            <Typography variant="h6">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>


      </div>








    </div>
  );
};

export default Buycredits;
